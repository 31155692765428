<template>
  <div>
    <router-view />
  </div>
</template>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  background-color: #5f6e8e;
}
</style>
