import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/home')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, form, next) => {
  // console.log(to);
  if (to.fullPath == '/') {
    next()
  } else {
    next({
      path: '/'
    })
  }

})
export default router
